import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { openapiClient } from '@/lib/openapi-fetch';
import { unifiedApiKeys } from '@/services/keys';

import { MercuryTrackersAccesor } from '@/constants/mercuryPages';

import type { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { FinishedIntelListFetchResponse } from '../types';

export const INTEL_ENDPOINTS = {
  [MercuryTrackersAccesor.finished]: '/issue/finished_intel/',
  [MercuryTrackersAccesor.raw]: '/issue/raw_intel/',
} as const;

export const useRawIntel = (
  params: Partial<UnifiedApiParams> = {},
  usePlaceholderData?: boolean,
) => {
  const list = () => useQuery({
    queryKey: unifiedApiKeys.records(INTEL_ENDPOINTS.raw, params),
    queryFn: () => openapiClient.GET(INTEL_ENDPOINTS.raw, { params: { query: params } }),
    placeholderData: usePlaceholderData ? keepPreviousData : undefined,
  });

  const summary = () => useQuery({
    queryKey: unifiedApiKeys.summary(INTEL_ENDPOINTS.raw, params),
    queryFn: () => openapiClient.GET(`${INTEL_ENDPOINTS.raw}summary`, { params: { query: params } }),
    placeholderData: usePlaceholderData ? keepPreviousData : undefined,
  });

  return {
    list,
    summary,
  };
};

export const useFinishedIntel = (
  params: Partial<UnifiedApiParams> = {},
  usePlaceholderData?: boolean,
) => {
  const list = <TResult = FinishedIntelListFetchResponse>(
    select: (data: FinishedIntelListFetchResponse) => TResult,
  ) => useQuery({
      queryKey: unifiedApiKeys.records(INTEL_ENDPOINTS.finished, params),
      queryFn: () => openapiClient.GET(INTEL_ENDPOINTS.finished, { params: { query: params } }),
      placeholderData: usePlaceholderData ? keepPreviousData : undefined,
      select,
    });

  const summary = () => useQuery({
    queryKey: unifiedApiKeys.summary(INTEL_ENDPOINTS.finished, params),
    queryFn: async () => openapiClient.GET(`${INTEL_ENDPOINTS.finished}summary`, { params: { query: params } }),
    placeholderData: usePlaceholderData ? keepPreviousData : undefined,
  });

  return {
    list,
    summary,
  };
};
