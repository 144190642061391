import { ReactNode } from 'react';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, boolean, optional, string } from 'superstruct';

import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';
import MercuryCheckbox from '@/components/content/form/components/Checkbox';

import { LoginFormFields, LoginFormLabels } from '../types';
import { required } from '@/utils/inputValidation';
import { OnSubmitFunc } from '@/types/form';
import RouterLink from '@/components/content/link/RouterLink';

interface LoginFormProps {
  username?: string;
  tfa?: boolean;
  signIn: OnSubmitFunc<LoginFormFields>;
  isSigningIn: boolean;
  renderMessage: () => ReactNode;
}

const LoginForm = ({ username, signIn, isSigningIn, tfa, renderMessage }: LoginFormProps) => {
  const schema = {
    username: required(string(), 'Username is required'),
    password: required(string(), 'Password is required'),
    code: optional(string()),
    remember_me: optional(boolean()),
  };

  const fullSchema = tfa ? { ...schema, code: required(string(), 'Code is required') } : schema;

  return (
    <Form<LoginFormFields>
      onSubmit={signIn}
      submitButton={{
        text: 'Sign In',
        disabled: isSigningIn,
      }}
      options={{ resolver: superstructResolver(ST(fullSchema)), defaultValues: { username } }}
      name="login-form"
      fields={LoginFormLabels}
      focusOn="password"
    >
      {({ register, formState: { errors } }) => (
        <>
          {renderMessage()}

          <MercuryTextInput<LoginFormFields>
            register={register}
            type="hidden"
            name="username"
            required
          />
          <MercuryTextInput<LoginFormFields>
            register={register}
            type="password"
            name="password"
            label={LoginFormLabels.password}
            error={errors?.password?.message}
            link={<RouterLink to="/forgotpassword">Forgot Password?</RouterLink>}
            required
          />

          {tfa && (
            <MercuryTextInput<LoginFormFields>
              register={register}
              type="text"
              name="code"
              label={LoginFormLabels.code}
              error={errors?.code?.message}
              autoComplete="off"
              required
            />
          )}

          <MercuryCheckbox<LoginFormFields> register={register} name="remember_me" label="Remember Me" />
        </>
      )}
    </Form>
  );
};

export default LoginForm;
