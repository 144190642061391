import { buildMetaQuery } from '@/features/meta/services/getMeta';
import { incidentsKeys } from './keys';

import type { UserId } from '@/types/api';

const incidentsManagmentMetaQuery = (tracker: string) => (userId: UserId) => buildMetaQuery({
  queryKey: incidentsKeys.meta(tracker),
  endpoint: `drp/${tracker}/table`,
  userId,
});

export const incidentsMetaQuery = incidentsManagmentMetaQuery(incidentsKeys.alerts);

export const takedownsMetaQuery = incidentsManagmentMetaQuery(incidentsKeys.takedowns);
