import { useQuery } from '@tanstack/react-query';
import { fetchData } from '@/lib/fetch';
import { postMutation } from '@/lib/react-query/mutate';
import { authKeys } from './keys';

const recover = 'auth/recover';
const recovertoken = (token: string) => `auth/recover/${token}`;

export const useValidResetTokenQuery = (token: string) => (
  useQuery({
    queryKey: authKeys.recovertoken(token),
    queryFn: () => fetchData({ endpoint: recovertoken(token) }),
    meta: {
      avoidAuthRedirection: true,
    },
  }));

export const useRecoverPasswordMutation = () => postMutation({
  endpoint: recover,
  mutationOptions: {
    meta: {
      avoidAuthRedirection: true,
    },
  },
});
