import { Group, Stack, Tooltip } from '@mantine/core';

import InfoIcon from '@/assets/icons/content/info.svg';
import FlagIcon from '@/assets/icons/content/flag.svg';

import classes from './RiskIndicators.module.css';

interface IndicatorProps {
  hasIndicator: boolean;
  context?: Array<string>;
  text: string;
}

const Indicator = ({
  hasIndicator,
  context,
  text,
}: IndicatorProps) => {
  if (!hasIndicator) return null;

  const hasContext = context && context.length > 0;

  return (
    <>
      <Group align="center" gap={5}>
        <FlagIcon className={classes.flag} />

        {text}

        {hasContext && (
          <Tooltip label={context.join(', ')} maw={220} multiline>
            <Stack align="center" className="noPrint">
              <InfoIcon width={18} height={18} />
            </Stack>
          </Tooltip>
        )}
      </Group>

      {hasContext && (
        <div className={classes.contextPrint}>
          {context.join(', ')}
        </div>
      )}
    </>
  );
};

export default Indicator;
