/* eslint-disable react/no-array-index-key */
import IssueGrid from './IssueGrid';
import ModuleWrapper from './ModuleWrapper';
import Submodule from './Submodule';
import IssueTabs from './IssueTabs';

import type { ModuleDef } from '../types/modulesv2';
import { EditContextProvider } from '../context/EditContext';

interface IssueModuleProps<TData> {
  module: ModuleDef<TData>;
}

const IssueModule = <TData extends unknown>({ module: issueModule }: IssueModuleProps<TData>) => {
  const { submodules, ...moduleProps } = issueModule;

  if (!issueModule || !issueModule.submodules.length) return null;

  if (issueModule.layout === 'grid') {
    return <IssueGrid title={issueModule.title} submodules={issueModule.submodules} noPrint={issueModule.noPrint} />;
  }

  if (issueModule.layout === 'tabs') {
    return <IssueTabs module={issueModule} />;
  }

  return (
    <ModuleWrapper {...moduleProps}>
      {issueModule.submodules.map((submodule, i) => (
        <EditContextProvider permissions={submodule.canEdit}>
          <Submodule
            submodule={submodule}
            key={`submodule-${i}-${submodule.title || issueModule.title || ''}`}
          />
        </EditContextProvider>
      ))}
    </ModuleWrapper>
  );
};

export default IssueModule;
