import UpsellAction from './UpsellAction';

interface UpsellProps {
  upsellType: string;
  upsellText: string;
}

const Upsell = ({ upsellType, upsellText }: UpsellProps) => (
  <>
    <h1>Premium Only</h1>

    <p>
      Thank you for your interest in this Premium content. This content is available exclusively to our{' '}
      <b>Premium Subscription Customers</b>.
    </p>

    <p>
      Our <b>Premium Subscription</b> is designed to provide you with the most comprehensive intelligence available.
      With upgraded access, you&apos;ll unlock a range of benefits including our <b>{upsellType}</b>. {upsellText}
    </p>

    <p>Please contact us to find out how to upgrade.</p>

    <UpsellAction />
  </>
);

export default Upsell;
