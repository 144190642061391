import { useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    navigate(generatePath(to, params), { replace: true });
  });

  return null;
};

export default Redirect;
