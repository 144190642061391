/* eslint-disable */

export const USER_INVITE = (user: string) =>
  `<p>A new token was generated for the user <b>${user}</b>. The user will be able to access the portal after choosing a password</p>`;

export const REGISTER_DISCLAIMER = `
<p>
  By clicking <b>Register</b>, you agree to our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Terms</a>.
  Learn how we collect, use and share your data in our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Data Policy</a> and how we use cookies and similar
  technology in our <a href="https://quointelligence.eu/terms-conditions/" target="_blank" rel="noreferrer">Cookie Policy</a>.
</p>`;

export const PASSWORD_INFO = `Please enter a password with at least 12 letters including a digit, mixed case letters and a special character (!#$%&'()*+,./:;?@[\]^_{|})`;
export const PASSWORD_RECOVERY_SENT =
  '<p>An email has been sent to the email address provided, please check your inbox for a reset link. If you do not receive this email in the next 15 minutes, please contact us at <a href="mailto:support@quointelligence.eu">support@quointelligence.eu</a> for further assistance.</p>';

export const INVALID_FORCED_2FA =
  'Your organisation has requested you to activate 2-factor authentication but we seem to be having some issues creating a valid token for you. Please try again later.\n\n If the issue persists please contact customer support.';

export const FINISHED_SUBTITLE = 'Finished Intelligence products by QuoIntelligence Analysis team.';
export const RAW_SUBTITLE =
  'Raw Intelligence coming from OSINT/CYBINT/HUMINT sources analysed and classified by QuoIntelligence analysis.';

export const BLOCKED_TABLE_MESSAGE = 'A premium subscription is needed';

export const SERVER_ERROR = 'We seem to be having an issue with this request, please try again later.';

export const DASHBOARD_UPSELL = 'These dashboards enable you to understand your most relevant threats in depth.';
export const ISSUES_UPSELL =
  'These reports are packed with valuable information that enables you to understand the latest threats in depth.';

export const IDENTITY_BETA_MESSAGE = "For the time being this panel is exclusively based on botnet log files. You can find same old credential leak alerts in the Incident Management panel";
