import { buildMetaQuery } from '@/features/meta/services/getMeta';

import type { UserId } from '@/types/api';
import { catalogsKeys } from '../../../services/keys';

export const vulnerabilitiesMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: catalogsKeys.meta('vulnerabilities'),
  endpoint: 'catalog/vulnerabilities/table',
  userId,
});
