import { buildMetaQuery } from '@/features/meta/services/getMeta';

import type { UserId } from '@/types/api';
import { catalogsKeys } from '../../../services/keys';

export const malwareKitsMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: catalogsKeys.meta('malware_kits'),
  endpoint: 'catalog/malware_kits/table',
  userId,
});
