import type { ReactNode } from 'react';
import { Group, Text } from '@mantine/core';

import { mercuryTrackers, TMercuryTrackersIds } from '@/constants/mercuryPages';
import type { Entries } from '@/utils';

import classes from '../Notifications.module.css';

interface NotificationProps {
  items: Entries<Record<TMercuryTrackersIds, number>>;
  emptyMsg: string;
  footer?: ReactNode;
}

const Notifications = ({ items, emptyMsg, footer: Footer }: NotificationProps) => (
  <>
    <ul>
      {items.map(([type, notification]) => (

        <li className={classes.menuItem} key={type}>
          <Group p={10}>
            <span><b>{mercuryTrackers[type].title}</b></span>

            <Text
              size="sm"
              classNames={{ root: classes.menuItemtext }}
              span
            >
              {notification}
            </Text>
          </Group>
        </li>
      ))}
    </ul>

    {items.length === 0 && (<Text size="lg" c="var(--quo-grey-50)" ta="center">No {emptyMsg} tickets</Text>)}

    {Footer}
  </>
);

export default Notifications;
