/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import type { ExtraProps as ReactMarkdownProps } from 'react-markdown';

import classes from '../Markdown.module.css';

const MarkdownImage = ({
  src,
  alt,
  title,
}: Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'ref'> &
ReactMarkdownProps) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <button type="button" onClick={open} aria-label={alt}>
        <img src={src} alt={alt} title={title} />
      </button>

      <Modal
        opened={opened}
        onClose={close}
        size="auto"
        classNames={{ header: classes.header }}
        centered
      >
        <img
          src={src}
          alt={alt}
          title={title}
          className={classes.image}
        />
      </Modal>
    </>
  );
};

export default MarkdownImage;
