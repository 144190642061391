import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, optional, string, any } from 'superstruct';

// Components
import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';

// Api
import { usePostPreCheck } from '../services/precheck';
import { useSamlStart } from '../services/saml';

// Types
import type { PrecheckReturn } from '../types';
import type { GeneralErrorFunc, OnSubmitFunc } from '@/types/form';

// Misc
import { required } from '@/utils/inputValidation';

interface UnauthorizedProps {
  redirectParam: string | null;
  onUnauthorizedPrecheck: (data: PrecheckReturn) => void;
}

type UnauthorizedFormFields = { username: string; redirect_path: string | null };
const UnauthorizedFormLabels = { username: 'Email Address' } as const;

const Unauthorized = ({ redirectParam, onUnauthorizedPrecheck }: UnauthorizedProps) => {
  const { isPending: isPrechecking, mutate: preCheck } = usePostPreCheck();
  const { mutate: startSaml, isPending: isRedirecting } = useSamlStart();

  const schema = ST({
    username: required(string(), 'Email Address is required'),
    redirect_path: optional(any()),
  });

  const redirectPath = redirectParam && new URL(String(redirectParam), window.location.origin).pathname;

  const handleSaml = (username: UnauthorizedFormFields['username'], setError: GeneralErrorFunc) => {
    startSaml(
      { username },
      {
        onError: (error) => setError(error?.message),
      },
    );
  };

  const onSubmitPrecheck: OnSubmitFunc<UnauthorizedFormFields> = (formData, setGeneralError) => {
    preCheck(formData, {
      onSuccess: (response) => {
        if (response.auth_type === 'SAML') {
          handleSaml(formData.username, setGeneralError);
        } else {
          onUnauthorizedPrecheck(response);
        }
      },
      onError: (error) => {
        setGeneralError(error?.message);
      },
    });
  };

  return (
    <Form<UnauthorizedFormFields>
      onSubmit={onSubmitPrecheck}
      submitButton={{
        text: 'Continue',
        disabled: isPrechecking || isRedirecting,
      }}
      options={{ resolver: superstructResolver(schema), defaultValues: { redirect_path: redirectPath } }}
      name="precheck-form"
      fields={UnauthorizedFormLabels}
      focusOn="username"
    >
      {({ register, formState: { errors } }) => (
        <>
          <MercuryTextInput<UnauthorizedFormFields>
            register={register}
            type="text"
            name="username"
            label={UnauthorizedFormLabels.username}
            error={errors?.username?.message}
            required
          />
          <MercuryTextInput<UnauthorizedFormFields> register={register} type="hidden" name="redirect_path" />
        </>
      )}
    </Form>
  );
};

export default Unauthorized;
