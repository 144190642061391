import { Group, GroupProps, Tooltip } from '@mantine/core';

import { capitalize } from '@/utils/string';
import classes from './Priority.module.css';

interface PrioProps extends GroupProps {
  priority: string | number;
  id: string;
}

const Priority = ({
  priority,
  id,
  ...groupProps
}: PrioProps) => {
  const getPriority = (p: string | number) => {
    switch (p) {
      case 'low':
      case 1:
        return { value: 1, name: 'low' };
      case 'normal':
      case 2:
        return { value: 2, name: 'normal' };
      case 'high':
      case 3:
        return { value: 3, name: 'high' };
      case 'advanced':
      case 4:
        return { value: 4, name: 'advanced' };
      default:
        return 0;
    }
  };

  const priorityValue = getPriority(priority);

  if (!priorityValue) return null;

  return (
    <Tooltip label={capitalize(priorityValue.name)}>
      <Group
        gap={5}
        classNames={{ root: `${classes.wrapper} priority-${priorityValue.value}` }}
        {...groupProps}
      >
        {[...Array(priorityValue.value)].map((el, i: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <span className={classes.triangle} key={`prio-${i}-${priorityValue.value}-${id}`} />))}
      </Group>
    </Tooltip>
  );
};

export default Priority;
