import { useQuery } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import type { RedmineCatalogField } from '@/types/mercury-data-types';

const CATALOGS = {
  SECTORS: 'sectors',
  COUNTRIES: 'countries',
} as const;

type Catalogs = typeof CATALOGS[keyof typeof CATALOGS];

const catalogQuery = (catalog: Catalogs) => ({
  queryKey: ['catalog', catalog],
  queryFn: () => fetchData({ endpoint: `mercury/catalog/${catalog}` }),
});

const useCatalogQuery = (catalog: Catalogs) => useQuery<Array<RedmineCatalogField>>(catalogQuery(catalog));

export const useGetIndustrySectors = () => useCatalogQuery(CATALOGS.SECTORS);

export const useGetCountries = () => useCatalogQuery(CATALOGS.COUNTRIES);
