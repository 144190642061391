import { postMutation } from '@/lib/react-query/mutate';
import type { UserId } from '@/types/api';

const markallasread = (userId: UserId) => `mercury/notifications/read/${userId}`;

export const useNotificationsMarkAllAsReadMutation = (userId: UserId) => (
  postMutation({
    endpoint: markallasread(userId),
  })
);
