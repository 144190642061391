export const authKeys = {
  all: ['auth'] as const,

  precheck: () => [...authKeys.all, 'precheck'] as const,

  userinfo: () => [...authKeys.all, 'userinfo'] as const,

  userInfoToken: (token: string) => [...authKeys.all, 'userinfo', token] as const,

  recovertoken: (token: string) => [...authKeys.all, 'passwordrecovery', token] as const,
};
