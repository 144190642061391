import { ACCESS_ROLES } from '@/constants/permissions';
import type { SubscriptionsTrials } from '@/types/mercury-data-types';
import type { PlainObject } from '@/types/general';
import type { UserId } from '@/types/api';

interface Quoins {
  purchased: number;
  spent: number;
  available: number;
}

type RelevantContext = {
  id: number;
  name: string;
  parents: Array<RelevantContext> | null;
  children: Array<RelevantContext> | null;
};

export interface UserInfo {
  user_id: UserId;
  entitlements: string[];
  entitlements_trial: Array<SubscriptionsTrials>;
  role: (typeof ACCESS_ROLES)[keyof typeof ACCESS_ROLES];
  client_access_id: string;
  two_factor_enabled: boolean;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  technologies: Record<string, string>;
  industry_sectors: Record<string, string>;
  locations: Record<string, string>;
  relevant_technologies: Array<RelevantContext>;
  relevant_industry_sectors: Array<RelevantContext>;
  relevant_locations: Array<RelevantContext>;
  company: string;
  two_factor_force_admin: boolean;
  quoins: Quoins;
  subclients: PlainObject;
  is_subclient: boolean;
}

export type LoginFormFields = {
  username: string;
  password: string;
  code: string;
  remember_me: boolean;
};

export const LoginFormLabels = {
  username: 'Email Address',
  password: 'Password',
  code: '2FA Code',
} as const;

export type RegisterFormFields = {
  email: string;
  firstname: string;
  lastname: string;
  client_name: string;
  password?: string;
  confirm_password?: string;
};

export const RegisterFormLabels = {
  email: 'Email',
  firstname: 'First Name',
  lastname: 'Last Name',
  client_name: 'Client',
  password: 'Password',
  confirm_password: 'Confirm Password',
} as const;

export type ResetPasswordFields = {
  password: string;
  new_password: string;
};

export const ResetPasswordLabels = {
  password: 'New Password',
  new_password: 'Retype new Password',
} as const;

export type ForgotPasswordFields = {
  email: string;
};

export const ForgotPasswordLabels = {
  email: 'Email Address',
} as const;

export interface PrecheckReturn {
  username: string;
  auth_type: 'NORMAL' | '2FA' | 'SAML';
}

export interface SAMLResponse {
  redirect_url: string;
}
