import type { ClassAttributes, LiHTMLAttributes } from 'react';
import type { ExtraProps } from 'react-markdown';
import { getFootnoteClass } from '../utils';

const ListItem = ({
  node,
  className,
  children,
  id,
  footnotesClass,
  ...props
}: ClassAttributes<HTMLLIElement> & LiHTMLAttributes<HTMLLIElement> & ExtraProps & { footnotesClass: string }) => {
  const isFootnote = id?.includes('user-content');
  let footnoteSelector = id;

  if (isFootnote) {
    footnoteSelector = `${getFootnoteClass(footnotesClass)}-${id} `;
  }

  return (
    <li {...props} id={footnoteSelector}>
      {children}
    </li>
  );
};

export default ListItem;
