import type { DateValueType } from '@/components/content/chart/types';
import type { RedmineEntityField } from '@/types/mercury-data-types';
import type { Includes, MetisIssueCommonFields } from '@/types/mercury-data-types/metis';
import type { UnifiedApiMethodsResponse } from '@/types/mercury-data-types/unifiedapi';
import type { BaseTicket, BaseTicketIncludes } from '../../issue/types/ticket';

export interface TakedownsGFX {
  takedown_by_provider_plot: Array<DateValueType>;
  takedown_by_provider: Array<RedmineEntityField>;
  takedown_by_status: Record<string, number>;
}

export const OTakedownStatus = {
  Approved: 'Approved for Production',
  Feedback: 'Feedback',
  New: 'New',
  Pending: 'Pending',
  Resolved: 'Resolved',
  Rejected: 'Rejected',
  Closed: 'Closed',
  InProgress: 'In Progress',
} as const;

type Statuses = typeof OTakedownStatus[keyof typeof OTakedownStatus];

export interface TakedownTableAlert {
  client_id: number;
  issue_id: number;
  created_on: string;
  updated_on: string;
  status_text: Statuses;
  service_provider: string;
  reference: string;
  client_name: string;
}

export interface MetisTakedown extends MetisIssueCommonFields {
  acknowledged_by: string;
  status: Statuses;
  incidents: Record<number, string>;
}

type TakedownTicketResponse = UnifiedApiMethodsResponse<'/issue/takedowns/{id}'>;

export type TakedownTicket = BaseTicket<Includes<TakedownTicketResponse, BaseTicketIncludes>>;
