import {
  type PropsWithChildren, createContext, useState, useContext, SetStateAction, Dispatch,
  useMemo,
} from 'react';

import { useMercuryPermissions } from '@/hooks/useMercuryPermissions';
import { Permissions } from '@/types/permissions';

interface EditContextValue {
  isEditing: boolean;
  allowedEdit: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

interface EditContentProviderProps {
  permissions?: Permissions;
}

const EditContext = createContext<EditContextValue | null>(null);

export const EditContextProvider = ({ children, permissions }: PropsWithChildren<EditContentProviderProps>) => {
  const [isEditing, setIsEditing] = useState(false);
  const { hasAccess } = useMercuryPermissions();
  const operator = permissions?.modules ? 'OR' : 'AND';
  const allowedEdit = permissions ? hasAccess(permissions, operator) : false;

  const value = useMemo(() => ({ isEditing, setIsEditing, allowedEdit }), [isEditing, setIsEditing]);

  return (
    <EditContext.Provider value={value}>
      {children}
    </EditContext.Provider>
  );
};

export const useEdit = () => {
  const editContext = useContext(EditContext);

  if (!editContext) {
    throw new Error('EditContext is not initialized. This hook must be used inside a EditContextProvider');
  }

  return editContext;
};
