import { postMutation } from '@/lib/react-query/mutate';

import type { SAMLResponse } from '../types';

export const useSamlStart = () => postMutation<SAMLResponse>({
  endpoint: 'auth/saml/start',
  mutationOptions: {
    onSuccess: (data) => {
      window.location.assign(data.redirect_url);
    },
    meta: {
      avoidAuthRedirection: true,
    },
  },
});
