import type { ClassAttributes, HTMLAttributes } from 'react';
import type { ExtraProps } from 'react-markdown';

import MercuryTitle from '../../title/Title';

const Title = ({
  node,
  id,
  className,
  children,
}: ClassAttributes<HTMLHeadingElement> & HTMLAttributes<HTMLHeadingElement> & ExtraProps) => {
  const getOrder = (level?: string) => {
    switch (level) {
      case 'h1':
        return 1;
      case 'h2':
        return 2;
      case 'h3':
        return 3;

      default:
        return 1;
    }
  };

  return (
    <MercuryTitle order={getOrder(node?.tagName)} id={id} className={className}>
      {children}
    </MercuryTitle>
  );
};

export default Title;
