import MetaWrapper from '@/features/meta/components/MetaWrapper';
import { Box, Flex, Skeleton } from '@mantine/core';

import { useGetCountries, useGetIndustrySectors } from '@/services/catalog';

import { isNotNull } from '@/utils';

import { Cardinalities, FiltersTypes } from '@/types/meta';
import type { RedmineCatalogField } from '@/types/mercury-data-types';
import type { MetaStore } from '@/hooks/useMetaStore';

const SearchFilters = ({
  metaStore,
}: { metaStore: MetaStore }) => {
  const sectors = useGetIndustrySectors();
  const locations = useGetCountries();

  if (sectors.isPending || locations.isPending) {
    return (
      <Flex gap={15} mt={25}>
        <Skeleton height={35} radius="4px" />
        <Skeleton height={35} radius="4px" />
        <Skeleton height={35} radius="4px" />
      </Flex>
    );
  }
  const getChoicesFromCatalog = (choice: RedmineCatalogField) => ({
    label: choice.name,
    description: choice.name,
    value: choice.id,
    parent_id: choice.parent_id,
  });

  const metaData = {
    meta: [
      {
        name: 'timeframe',
        label: 'Timeframe',
        description: '',
        type: FiltersTypes.date,
        cardinality: Cardinalities.range,
      },
      !sectors.isError ? {
        name: 'industries.id',
        type: FiltersTypes.string,
        cardinality: Cardinalities.multiple,
        description: 'Industries',
        label: 'Industries',
        choices: sectors.data.map(getChoicesFromCatalog),
      } : null,
      !locations.isError ? {
        name: 'locations.id',
        type: FiltersTypes.string,
        cardinality: Cardinalities.multiple,
        description: 'Locations',
        label: 'Locations',
        choices: locations.data.map(getChoicesFromCatalog),
      } : null,
    ].filter(isNotNull),
    defaultValues: null,
  };

  return (
    <Box mt={25}>
      <MetaWrapper
        metaData={metaData}
        metaStore={metaStore}
        hideSearch
        hideClear
      />
    </Box>
  );
};

export default SearchFilters;
