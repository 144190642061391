import {
  Children, ClassAttributes, isValidElement, ReactNode, TableHTMLAttributes, useMemo,
} from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import type { ExtraProps } from 'react-markdown';

import classes from '../Markdown.module.css';

const Table = ({
  children,
}: ClassAttributes<HTMLTableElement> & TableHTMLAttributes<HTMLTableElement> & ExtraProps) => {
  const extractElementsByType = (children: ReactNode, type: string) => (
    Children.toArray(children).filter((child) => isValidElement(child) && child.type === type).flatMap((child) => (
      Children.toArray((child as any).props.children).filter((child) => isValidElement(child) && child.type === 'tr')
    ))
  );

  const rows = useMemo(() => extractElementsByType(children, 'tbody'), []);
  const headers = useMemo(() => extractElementsByType(children, 'thead'), []);
  const rowsHeight = (rows.length + 2) * 45;

  return (
    <>
      <TableVirtuoso
        style={{ height: rowsHeight > 500 ? 500 : rowsHeight }}
        data={rows}
        fixedHeaderContent={() => headers}
        className={`${classes.tableContainer} noPrint`}
        itemContent={(index, row) => (isValidElement(row) ? row.props.children : row)}
      />

      <table className={classes.tablePrint}>
        {children}
      </table>
    </>
  );
};

export default Table;
