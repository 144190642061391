export type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const maxOutArray = <T = any, TLimit = any>(
  arr: Array<T>,
  max?: number,
  limitText?: (text: string
  ) => TLimit) => {
  if (!max || max >= arr.length) return arr;

  const text = `+${arr.length - max}`;
  const isLimitText = limitText ? limitText(text) : text;

  return [...arr.slice(0, max), isLimitText];
};

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

export const getValues = <T extends object>(obj: T) => Object.values(obj) as T[keyof T][];

export const getKeys = <T extends object>(obj: T) => Object.keys(obj) as (keyof T)[];

export const objectHasValues = (obj: Record<string, unknown>) => getValues(obj).length > 0;
