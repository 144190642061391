import { Transition } from '@mantine/core';

import Tabs from '@/components/content/tabs/Tabs';
import DropdownMenu from '@/components/layout/nav/topbar/DropdownMenu';
import NotificationsBadgeIcon from './components/BadgeIcon';
import Notifications from './components/Notifications';
import NotificationsReadAll from './components/ReadAll';

import classes from './Notifications.module.css';
import { useGetNotifications } from './services/getNotifications';

const NotificationsMenu = () => {
  const {
    data,
    isLoading,
    refetch,
  } = useGetNotifications();

  const {
    unread,
    unacknowledged,
    totalUnread,
    totalUnacknowledged,
    total,
  } = data || {};

  const tabs = [
    {
      tab: `Unread (${totalUnread})`,
      val: 'unread',
    },
    {
      tab: `Unacknowledged (${totalUnacknowledged})`,
      val: 'unacknowledged',
    },
  ];

  return (
    <Transition
      mounted={!!total && total > 0}
      transition="slide-left"
      duration={300}
      timingFunction="ease"
    >
      {(styles) => (
        <div style={styles}>
          <DropdownMenu
            icon={<NotificationsBadgeIcon totalNotifications={total ?? 0} />}
            size={335}
            hideArrow={isLoading}
          >
            <div className={classes.wrapper}>
              <h1 className={classes.title}>Notifications</h1>

              <Tabs
                tabs={tabs}
                id="notifications-menu"
                defaultValue="unacknowledged"
                small
                justify="space-between"
                mt={25}
                grow
              >
                <Notifications
                  items={unread ?? []}
                  footer={<NotificationsReadAll refetch={refetch} />}
                  emptyMsg="unread"
                />
                <Notifications
                  items={unacknowledged ?? []}
                  emptyMsg="unalcknowledged"
                />
              </Tabs>
            </div>
          </DropdownMenu>
        </div>
      )}
    </Transition>
  );
};

export default NotificationsMenu;
