import { UnifiedApiParams } from '@/types/mercury-data-types/unifiedapi';
import type { Signal } from '@/types/mercury-data-types/signals';

import { findInObject } from '@/utils/findInObject';
import { unifiedApiKeys } from '../keys';
import { getEntries } from '@/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { openapiClient } from '@/lib/openapi-fetch';

const BASE_PATHS = {
  COMMON: 'common',
  STRUCTURED: 'data.structured',
  CATALOG: 'catalog',
  METADATA: 'metadata',
} as const;

export const SIGNALS_PATHS = {
  ID: ['signal_id'],
  TITLE: [
    `${BASE_PATHS.COMMON}.title`,
    `${BASE_PATHS.STRUCTURED}.title`,
  ],
  TEXT: [
    `${BASE_PATHS.COMMON}.quo_summary`,
    `${BASE_PATHS.COMMON}.summary`,
    `${BASE_PATHS.STRUCTURED}.content.summary`,
    `${BASE_PATHS.STRUCTURED}.content.text`,
    `${BASE_PATHS.STRUCTURED}.Text`,
  ],
  TIMESTAMP: [
    `${BASE_PATHS.COMMON}.timestamp`,
    `${BASE_PATHS.STRUCTURED}.timestamp`,
    '@timestamp',
  ],
  PUBLISHED_AT: [`${BASE_PATHS.METADATA}.published_at`],
  UPDATED_AT: [`${BASE_PATHS.METADATA}.updated_at`],
  INGESTED_AT: [`${BASE_PATHS.METADATA}.ingested_at`],
  LINK: [`${BASE_PATHS.METADATA}.link`],
  CATEGORY: [`${BASE_PATHS.CATALOG}.category`],
  THREAT_ACTOR: [`${BASE_PATHS.CATALOG}.threatactor`],
  MALWARE_KIT: [`${BASE_PATHS.CATALOG}.malwarekit`],
  VULNERABILITY: [`${BASE_PATHS.CATALOG}.vulnerability`],
  SOURCE_ID: [`${BASE_PATHS.CATALOG}.source_id`],
  SOURCE_NAME: [
    `${BASE_PATHS.CATALOG}.source`,
    `${BASE_PATHS.METADATA}.collector.name`,
  ],
  TYPE: [`${BASE_PATHS.CATALOG}.type`],
  INDUSTRY_ID: [`${BASE_PATHS.CATALOG}.industrysector_id`],
  INDUSTRY_NAME: [`${BASE_PATHS.CATALOG}.industrysector`],
  TECHNOLOGY_ID: [`${BASE_PATHS.CATALOG}.technology_id`],
  TECHNOLOGY: [`${BASE_PATHS.CATALOG}.technology`],
  LOCATION_ID: [`${BASE_PATHS.CATALOG}.country_id`],
  LOCATION_NAME: [`${BASE_PATHS.CATALOG}.country`],
};

const moveToObject = (
  data: Record<string, never>,
  { id, name }: { id: Array<string>; name: Array<string> },
): Record<string, any> => {
  const ids = findInObject(data, id, []) as Array<any>;
  const names = findInObject(data, name, []) as Array<any>;

  // return {key: value}f format:
  return ids.reduce((accu, id, index) => {
    accu[id] = names[index];

    return accu;
  }, {});
};

export type NormalizedSignal = ReturnType<typeof normalizeSignal>;

export const normalizeSignal = (signal: Signal) => ({
  id: signal.signal_id as any,
  title: findInObject(signal, SIGNALS_PATHS.TITLE),
  text: findInObject(signal, SIGNALS_PATHS.TEXT),
  timestamp: findInObject(signal, SIGNALS_PATHS.TIMESTAMP),
  published_at: findInObject(signal, SIGNALS_PATHS.PUBLISHED_AT),
  updated_at: findInObject(signal, SIGNALS_PATHS.UPDATED_AT),
  ingested_at: findInObject(signal, SIGNALS_PATHS.INGESTED_AT),
  link: findInObject(signal, SIGNALS_PATHS.LINK),
  type: findInObject(signal, SIGNALS_PATHS.TYPE),
  catalog: {
    category: findInObject(signal, SIGNALS_PATHS.CATEGORY),
    vulnerability: findInObject(signal, SIGNALS_PATHS.VULNERABILITY),
    threatactor: findInObject(signal, SIGNALS_PATHS.THREAT_ACTOR),
    malwarekit: findInObject(signal, SIGNALS_PATHS.MALWARE_KIT),
    source: findInObject(signal, SIGNALS_PATHS.SOURCE_NAME),
    source_id: findInObject(signal, SIGNALS_PATHS.SOURCE_ID),
    technologies: moveToObject(signal, {
      id: SIGNALS_PATHS.TECHNOLOGY_ID,
      name: SIGNALS_PATHS.TECHNOLOGY,
    }),
    locations: moveToObject(signal, {
      id: SIGNALS_PATHS.TECHNOLOGY_ID,
      name: SIGNALS_PATHS.TECHNOLOGY,
    }),
    industry_sectors: moveToObject(signal, {
      id: SIGNALS_PATHS.INDUSTRY_ID,
      name: SIGNALS_PATHS.INDUSTRY_NAME,
    }),
  },
});

export const useSignals = ({ filter }: Partial<UnifiedApiParams>) => {
  const summary = () => useQuery({
    queryKey: unifiedApiKeys.summary('/signals/summary', { filter }),
    queryFn: async () => openapiClient.GET('/signals/summary', { params: { query: { filter } } }),
    select: (data) => ({
      ...data,
      area: data.data ? getEntries(data.data?.signal_timeline).map(([key, value]) => ([+new Date(key), value])) : [],
    }),
    placeholderData: keepPreviousData,
  });

  return {
    summary,
  };
};
