import IocList from '../../issue/modules/IocList';
import TableActions from '../components/TableActions';
import Pill from '@/components/content/pills/Pill';

import { toDate, toDateTime } from '@/utils/toDate';
import { createHelper } from '../../issue/utils/helper';
import { incidentsStatusColorCoding } from '../utils';

import type { Module } from '../../issue/types/modulesv2';
import type { IncidentsTableAlert } from '../types';
import { ExtraDataObj } from '@/types/mercury-data-types';
import Markdown from '@/components/content/markdown/Markdown';
import { upperFirst } from '@mantine/hooks';

const helper = createHelper<IncidentsTableAlert & { extra: ExtraDataObj }>();

export const incidentModalModules = ({
  hideActions,
}: { hideActions?: boolean }): Array<Module<IncidentsTableAlert & { extra: ExtraDataObj }>> => [
  {
    id: 'overview',
    direction: { base: 'column', s: 'row' },
    wrap: 'wrap',
    submodules: [
      helper.accessor((data) => data.created_on, {
        title: 'Created:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        element: ({ getValue }) => toDate(getValue()),
        scroll: false,
      }),
      helper.accessor((data) => data.status, {
        title: 'Status:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        element: ({ getValue }) => (
          <Pill
            component="span"
            color="var(--quo-white)"
            pillProps={incidentsStatusColorCoding}
            value={getValue().toLowerCase()}
            transform={(value) => upperFirst(value)}
          />
        ),
        scroll: false,
      }),
      helper.accessor((data) => data.priority_name, {
        title: 'Priority:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        scroll: false,
      }),
      helper.accessor((data) => data.drp_category, {
        title: 'Category:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        scroll: false,
      }),
      helper.accessor((data) => data.drp_subcategory, {
        title: 'Subcategory:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        scroll: false,
      }),
      helper.accessor((data) => data.source, {
        title: 'Source:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        element: ({ getValue }) => getValue()[0].name,
        scroll: false,
      }),
      helper.accessor((data) => data.updated_on, {
        title: 'Last Update:',
        direction: { base: 'column', s: 'row' },
        align: { sm: 'center' },
        element: ({ getValue }) => toDateTime(getValue()),
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.iocs, {
        title: 'IoC List',
        canCopy: true,
        element: ({ getValue }) => <IocList iocs={getValue()} />,
      }),
    ],
  },
  {
    removeBackground: true,
    hide: hideActions,
    noPrint: true,
    submodules: [
      helper.accessor((data) => data, {
        title: 'Actions',
        direction: { base: 'column', s: 'row' },
        justify: 'space-between',
        element: ({ getValue }) => {
          const { issue_id: id, status } = getValue();

          return (
            <TableActions
              issueId={id}
              status={status}
              asButton
            />
          );
        },
        scroll: false,
      }),
    ],
  },
  {
    submodules: [
      helper.accessor((data) => data.description, {
        title: 'Description',
        element: ({ getValue }) => <Markdown text={getValue()} disallowedElements={['img']} />,
      }),
    ],
  },
];
