import type { PropsWithChildren, ReactNode } from 'react';

import Button from '../button/Button';
import Upsell from '../upsell/Upsell';
import FullPageContent from '../box/FullPageContent';

import type { FetchError } from '@/types/api';

import { sanitize } from '@/utils/sanitize';

import classes from './ErrorWrapper.module.css';

type UpsellProps = {
  canUpsell: true;
  upsellText: string;
} | {
  canUpsell?: false;
  upsellText?: undefined;
};

type ErrorWrapperProps = {
  isError: boolean;
  refetch?: VoidFunction;
  error?: FetchError | null;
  errors?: Record<string | number, { title?: string; message: string; action?: ReactNode; }>;
} & UpsellProps;

const shouldUpsell = (code: number) => code === 402;

const ErrorWrapper = ({
  children,
  isError,
  refetch,
  error,
  errors,
  canUpsell,
  upsellText,
}: PropsWithChildren<ErrorWrapperProps>) => {
  const renderError = () => {
    if (error && canUpsell && shouldUpsell(error.code)) {
      return (
        <Upsell upsellText={upsellText} upsellType="Premium Reports" />
      );
    }

    if (errors && error && errors[error.code]) {
      return (
        <>
          {errors[error.code].title && <h1>{errors[error.code].title}</h1>}
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: sanitize(errors[error.code].message) }} />

          {errors[error.code].action && <div className={classes.actionWrapper}>{errors[error.code].action}</div>}
        </>
      );
    }

    return (
      <>
        <h1>Sorry</h1>

        <p>
          We seem to be having an issue loading this content. Please retry or contact your dedicated customer
          success manager at <a href="mailto:support@quointelligence.eu">support@quointelligence.eu</a> for
          further assistance.
        </p>

        {refetch && <Button onClick={refetch}>Retry</Button>}
      </>
    );
  };

  return (
    <>
      {isError ? (
        <FullPageContent>
          {renderError()}
        </FullPageContent>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorWrapper;
