import { Stack, Text } from '@mantine/core';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, string } from 'superstruct';

// Components
import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';
import Title from '@/components/content/title/Title';

// Api
import { useSendPasswordRecoveryMutation } from '@/services/password';

// Types
import type { OnSubmitFunc } from '@/types/form';
import { ForgotPasswordFields, ForgotPasswordLabels } from '../types';

// Misc
import { required } from '@/utils/inputValidation';

// Styles
import { PASSWORD_RECOVERY_SENT } from '@/constants/text/english';

import classes from './Auth.module.css';

const ForgotPassword = () => {
  const { isPending: isRecoveringPassword, mutate: postSendRecovery } = useSendPasswordRecoveryMutation();

  const schema = ST({
    email: required(string(), 'Email is required'),
  });

  const sendRecovery: OnSubmitFunc<ForgotPasswordFields> = (formData, setGeneralError, setSuccessMessage) => {
    postSendRecovery(formData, {
      onSuccess: () => {
        setSuccessMessage(PASSWORD_RECOVERY_SENT, true, { customHtml: true });
      },
      onError: (error) => {
        setGeneralError(error);
      },
    });
  };

  return (
    <Stack align="center" justify="center" classNames={{ root: classes.wrapper }}>
      <div className={classes.content}>
        <Title order={1}>
          Password Reset
        </Title>

        <Text>
          Please enter the email address you used to register your account.
          We will send you a link that you can use to reset your password.
        </Text>

        <Form<ForgotPasswordFields>
          onSubmit={sendRecovery}
          submitButton={{
            text: 'Reset Password',
            disabled: isRecoveringPassword,
          }}
          options={{ resolver: superstructResolver(schema) }}
          fields={ForgotPasswordLabels}
        >
          {({ register, formState: { errors } }) => (
            <MercuryTextInput<ForgotPasswordFields>
              register={register}
              type="text"
              name="email"
              label={ForgotPasswordLabels.email}
              error={errors?.email?.message}
              required
            />
          )}
        </Form>
      </div>
    </Stack>
  );
};

export default ForgotPassword;
