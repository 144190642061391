import {
  ActionIcon, Box, Popover, Stack, Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import RequestInformationForm from '../../mailboxes/SupportForm';
import TakedownIcon from '@/assets/icons/content/takedown.svg';
import Button from '@/components/content/button/Button';

import { useToast } from '@/hooks/useToast';
import { useQueryActions } from '@/hooks/useQueryActions';
import { incidentsKeys } from '../services/keys';

import type { FetchError } from '@/types/api';
import { SUPPORT_TYPES } from '../../mailboxes/types';
import { unifiedApiKeys } from '@/services/keys';
import { INCIDENTS_ENDPOINTS } from '../services/issue';
import { today } from '@/components/content/datepicker/presets';

interface RequestTakedownProps {
  issueId: number;
  disabled?: boolean;
  loading?: boolean;
  asButton?: boolean;
  size?: number;
}

const RequestTakedown = ({
  issueId,
  loading,
  disabled,
  asButton,
  size = 20,
}: RequestTakedownProps) => {
  const [opened, { open, close, toggle }] = useDisclosure(false);
  const { invalidateQueries } = useQueryActions();
  const { createToast } = useToast();

  const onFormSuccess = () => {
    invalidateQueries([
      incidentsKeys.table(incidentsKeys.alerts),
      unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
    ]);

    createToast('Takedown request sent succesfully', 'success');

    close();
  };

  const onError = (error: FetchError) => {
    createToast(error.message, 'error');
    close();
  };

  return (
    <Popover
      width={300}
      opened={opened}
      onChange={toggle}
      position="bottom"
      withinPortal={false}
      withArrow
      shadow="md"
      trapFocus
    >
      <Popover.Target>
        {asButton ? (
          <Box w={{ base: '100%', s: 'auto' }}>
            <Button
              onClick={open}
              size="sm"
              rightSection={<TakedownIcon width={19} height={19} />}
              disabled={disabled}
              loading={loading}
            >
              Request Takedown
            </Button>
          </Box>
        ) : (
          <Stack align="center">
            <Tooltip label="Request a takedown">
              <ActionIcon
                size={size}
                variant="subtle"
                aria-label={`Request a takedown for ${issueId}`}
                disabled={disabled}
                loading={loading}
                loaderProps={{ size: size - 4 }}
                onClick={open}
              >
                <TakedownIcon />
              </ActionIcon>
            </Tooltip>
          </Stack>
        )}

      </Popover.Target>
      <Popover.Dropdown>
        <RequestInformationForm
          disabledFields={{ subject: true }}
          hideFields={{
            subject: true,
            due_date: true,
            subclient_id: true,
          }}
          name="takedown-form"
          onSuccess={onFormSuccess}
          onError={onError}
          relationship="takedowns"
          prefillData={{
            subject: 'Takedown Request',
            description: `I would like to request a Takedown for #${issueId}`,
            due_date: today.add({ days: 1 }).toString(),
            ticket_id: `${issueId}`,
            to: SUPPORT_TYPES.TAKEDOWN,
          }}
          subjects={['Takedown Request']}
          attachment
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default RequestTakedown;
