import { buildMetaQuery } from '@/features/meta/services/getMeta';
import { intelligenceKeys } from './keys';
import { type FiltersMeta, Cardinalities } from '@/types/meta';

import type { UserId } from '@/types/api';

export const rawMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: intelligenceKeys.meta('raw'),
  endpoint: 'intel/raw/table',
  userId,
});

export const finishedMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: intelligenceKeys.meta('finished'),
  endpoint: 'intel/finished/table',
  userId,
});

export const nonMetaFilters: FiltersMeta = [
  {
    name: 'unread',
    label: 'Unread Only',
    description: 'Show tickets',
    type: 'boolean',
    cardinality: Cardinalities.single,
    non_meta: true,
  },
];
