import { useGetPreCheck } from '../services/precheck';
import { useQueryActions } from '@/hooks/useQueryActions';
import { PrecheckReturn } from '../types';
import { authKeys } from '../services/keys';

export const useAuthType = (avoidPrecheck: boolean) => {
  const { setData } = useQueryActions();
  const { data: precheck, isLoading: isGettingType, isError: noPrecheckData } = useGetPreCheck(!avoidPrecheck);

  const isUnauthorized = !precheck && !isGettingType && noPrecheckData;

  const setPrecheckData = (data: PrecheckReturn | null) => {
    setData<PrecheckReturn | null>(authKeys.precheck(), () => data);
  };

  return {
    precheck,
    isUnauthorized,
    isGettingType,
    setPrecheckData,
  };
};
