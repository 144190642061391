import { buildMetaQuery } from '@/features/meta/services/getMeta';

import type { UserId } from '@/types/api';
import { catalogsKeys } from '../../../services/keys';

export const threatActorsMetaQuery = (userId: UserId) => buildMetaQuery({
  queryKey: catalogsKeys.meta('threat_actors'),
  endpoint: 'catalog/threat_actors/table',
  userId,
});
