/* eslint-disable react/no-array-index-key */
import { Fragment, type ReactNode } from 'react';
import { Group } from '@mantine/core';

import Title from '@/components/content/title/Title';
import DrawerLink from '../../drawerlink/DrawerLink';
import IssueIDLinkv2 from '../../issueIdLink/IssueIdLink';
import Markdown from '@/components/content/markdown/Markdown';

import type { Tracker } from '@/constants/mercuryPages';

import classes from './Results.module.css';

interface BaseResultProps {
  title: { id: number; name: string };
  tracker?: Tracker;
  content: string | null;
  drawerContent?: ReactNode;
  customLink?: ReactNode;
  footer: Array<ReactNode>;
}

const BaseResult = ({ title: { id, name }, content, tracker, drawerContent, customLink, footer }: BaseResultProps) => (
  <div className={classes.resultWrapper} data-testid="search-result">
    <Title order={3} size="h4">
      <>
        {customLink && customLink}

        {drawerContent && (
          <DrawerLink
            id={id}
            value={`${id} | ${name}`}
            content={drawerContent}
            link={tracker ? <IssueIDLinkv2 value={id} name="See More" tracker={tracker} useParent /> : null}
          />
        )}

        {!drawerContent && !customLink && (
          <IssueIDLinkv2
            value={id}
            name={`${id} | ${name}`}
            tracker={tracker}
            useParent
          />
        )}
      </>
    </Title>

    {content && (
      <Markdown
        text={content}
        disallowedElements={['table', 'img', 'section']}
        textProps={{
          lineClamp: 2,
          mt: 15,
          classNames: { root: classes.descriptionText },
        }}
      />
    )}

    {footer.length > 0 && (
      <Group mt={25} align="center">
        {footer.map((item, index) => <Fragment key={`result-footer-item-id-${index}`}>{item}</Fragment>)}
      </Group>
    )}
  </div>
);

export default BaseResult;
