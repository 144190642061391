import { postMutation } from '@/lib/react-query/mutate';

const completeregistration = (token: string) => `auth/token/complete_registration/${token}`;

export const useCompleteRegistrationMutation = (token: string) => postMutation({
  endpoint: completeregistration(token),
  method: 'PUT',
  requestOptions: {
    error: 'Sorry! There was an issue trying to complete your registration.',
  },
  mutationOptions: {
    meta: {
      avoidAuthRedirection: true,
    },
  },
});
