// Components
import { Tooltip } from '@mantine/core';
import classes from './InformationReliability.module.css';

const InformationReliability = ({ value, compact }: { value: string | null; compact?: boolean }) => {
  if (!value) { return null; }

  const [score, tooltip] = value ? value.split('-') : [];

  return (
    <Tooltip label={tooltip}>
      <span className={`
          ${classes.reliability}
          ${classes[`score--${score.trim()}`]}
          ${compact ? classes.compact : ''}
        `}
      >
        {score}
      </span>
    </Tooltip>
  );
};

export default InformationReliability;
