import type { ReactNode } from 'react';
import { Text, Tooltip } from '@mantine/core';

import List from '@/components/content/list/List';

import type { NormalizedCatalogListItem, NormalizedRedmineField } from '../types';

interface RedmineFieldListProps {
  items: NormalizedRedmineField;
  renderItem?: (item: NormalizedCatalogListItem) => ReactNode;
  highlight?: Array<unknown>;
}

const RedmineFieldList = ({ items, highlight, renderItem }: RedmineFieldListProps) => (
  <List
    items={items}
    renderItem={renderItem || (({ id, name, amount }) => (
      <>
        {highlight?.includes(Number(id)) ? (
          <Tooltip label="Matches Your Relevancy">
            <Text c="light-dark(var(--quo-blue),var(--quo-blue-50))" fw="bold" span>{name}</Text>
          </Tooltip>
        )
          : name} {amount && `(${amount})`}
      </>
    ))}
  />
);

export default RedmineFieldList;
