import { useQuery } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import { useAuth } from '@/hooks/useAuth';

import { getEntries } from '@/utils';
import { notificationsKeys } from './keys';

import { NotificationsType, type NotificationRecord, type Notification } from '../types';
import { MercuryTrackersIds, TMercuryTrackersIds } from '@/constants/mercuryPages';

const NOTIFICATION_MAP = {
  [NotificationsType.INTEL_FINISHED]: MercuryTrackersIds.finished,
  [NotificationsType.INTEL_RAW]: MercuryTrackersIds.raw,
  [NotificationsType.VULNERABILITIES]: MercuryTrackersIds.vulnerabilities,
  [NotificationsType.DRP_ALERTS]: MercuryTrackersIds.alerts,
  [NotificationsType.DRP_TAKEDOWNS]: MercuryTrackersIds.takedowns,
  [NotificationsType.RFI]: MercuryTrackersIds.rfi,
};

export const useGetNotifications = () => {
  const { userInfo: { user_id: userId } } = useAuth();

  return (
    useQuery({
      queryKey: notificationsKeys.notifications(),
      queryFn: () => fetchData({ endpoint: `mercury/notifications/${userId}` }),
      select: (data: NotificationRecord) => {
        const mapped = Object.entries(data).reduce((acc, [key, notification]) => {
          const trackerId = NOTIFICATION_MAP[key as keyof NotificationRecord];

          return {
            ...acc,
            [trackerId]: notification,
          };
        }, {} as Record<TMercuryTrackersIds, Notification>);

        const unread = getEntries({
          [MercuryTrackersIds.finished]: mapped[MercuryTrackersIds.alerts].unread,
          [MercuryTrackersIds.raw]: mapped[MercuryTrackersIds.takedowns].unread,
          [MercuryTrackersIds.vulnerabilities]: mapped[MercuryTrackersIds.vulnerabilities].unread,
        });

        const unacknowledged = getEntries({
          [MercuryTrackersIds.alerts]: mapped[MercuryTrackersIds.alerts].unacknowledged,
          [MercuryTrackersIds.takedowns]: mapped[MercuryTrackersIds.takedowns].unacknowledged,
          [MercuryTrackersIds.vulnerabilities]: mapped[MercuryTrackersIds.vulnerabilities].unacknowledged,
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const totalUnread = unread.reduce((acc, [_, item]) => acc + item, 0);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const totalUnacknowledged = unacknowledged.reduce((acc, [_, item]) => acc + item, 0);

        return {
          unread,
          unacknowledged,
          totalUnread,
          totalUnacknowledged,
          total: totalUnread + totalUnacknowledged,
        };
      },
    })
  );
};
