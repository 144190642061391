import { useQuery } from '@tanstack/react-query';

import { postMutation } from '@/lib/react-query/mutate';
import { fetchData } from '@/lib/fetch';

import type { PrecheckReturn } from '../types';
import { authKeys } from './keys';

const preCheck = 'auth/login/precheck';

export const usePostPreCheck = () => postMutation<PrecheckReturn>({
  endpoint: preCheck,
  mutationOptions: {
    meta: {
      avoidAuthRedirection: true,
    },
  },
});

export const useGetPreCheck = (enabled: boolean) => useQuery<PrecheckReturn>({
  queryKey: authKeys.precheck(),
  queryFn: () => fetchData({ endpoint: preCheck }),
  gcTime: 0,
  enabled,
  meta: {
    avoidAuthRedirection: true,
  },
});
