import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { FetchError } from '@/types/api';

export const useGetDrawerData = <TData, TSelectData>(
  query: UseQueryOptions<TData, FetchError, TSelectData>,
  enabled: boolean,
) => {
  const { queryKey, queryFn, select } = query;
  return useQuery({
    queryKey,
    queryFn,
    select,
    enabled,
  });
};
