import { getValues } from '@/utils';
import { useAuth } from './useAuth';

export const useMSSP = () => {
  const auth = useAuth();

  const subclients = getValues(auth.userInfo.subclients ?? []);
  const isMSSP = subclients.length > 0;
  const isSubclient = auth.userInfo.is_subclient;

  return { isMSSP, isSubclient, subclients };
};
