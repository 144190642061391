import { useSearchParams, Navigate, useNavigate } from 'react-router-dom';
import { Stack } from '@mantine/core';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST } from 'superstruct';

// Components
import Title from '@/components/content/title/Title';
import Loading from '@/components/content/loading/Loading';
import Form from '@/components/content/form/Form';
import MercuryTextInput from '@/components/content/form/components/TextInput';

// Api
import { useRecoverPasswordMutation, useValidResetTokenQuery } from '../services/password';

// Types
import { OnSubmitFunc } from '@/types/form';
import { ResetPasswordFields, ResetPasswordLabels } from '../types';

// Misc
import { match, password } from '@/utils/inputValidation';
import { PASSWORD_INFO } from '@/constants/text/english';

import classes from './Auth.module.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') as string;
  const { isError: isInvalidToken, isLoading: isVerifyingToken } = useValidResetTokenQuery(token);
  const { isPending: isResetingPassword, mutate: postRecoverPassword } = useRecoverPasswordMutation();

  const schema = ST({
    password: password('Password is not valid'),
    new_password: match('password', 'Passwords don\'t match'),
  });

  if (isVerifyingToken) {
    return <Loading text="Verifying" height="100vh" />;
  }

  if (isInvalidToken) {
    return <Navigate to="/login" />;
  }

  const recoverPassword: OnSubmitFunc<ResetPasswordFields> = (formData, setGeneralError) => {
    postRecoverPassword({ token, new_password: formData.password }, {
      onSuccess: () => {
        navigate('/login', { replace: true });
      },
      onError: (error) => {
        setGeneralError(error);
      },
    });
  };

  return (
    <Stack align="center" justify="center" classNames={{ root: classes.wrapper }}>
      <div className={classes.content}>
        <Title order={1}>
          Reset Password
        </Title>

        <Form<ResetPasswordFields>
          onSubmit={recoverPassword}
          submitButton={{ text: 'Reset Password', disabled: isResetingPassword }}
          options={{ resolver: superstructResolver(schema) }}
          fields={ResetPasswordLabels}
        >
          {({ register, formState: { errors } }) => (
            <>
              <MercuryTextInput<ResetPasswordFields>
                register={register}
                type="password"
                name="password"
                label={ResetPasswordLabels.password}
                error={errors?.password?.message}
                description={PASSWORD_INFO}
                displayError
                required
              />
              <MercuryTextInput<ResetPasswordFields>
                register={register}
                type="password"
                name="new_password"
                label={ResetPasswordLabels.new_password}
                error={errors?.new_password?.message}
                displayError
                required
              />
            </>
          )}
        </Form>
      </div>
    </Stack>
  );
};

export default ResetPassword;
