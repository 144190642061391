import { type TableData, serverTableNormalize } from '@/features/tablewrapper/services/tableTransforms';

import { normalizeTicketSources } from '@/utils/normalizeRedmineArrayProp';

import type { ServerPaginatedTable } from '@/types/mercury-data-types';
import type { IncidentsTableAlertRaw, IncidentsTableAlert } from '../types';

export const INCIDENTS_ENDPOINTS = {
  incidents: '/issue/incidents/',
  takedowns: '/issue/takedowns/',
} as const;

export const incidentTableSelect = (
  data: ServerPaginatedTable<Array<IncidentsTableAlertRaw>>,
): TableData<Array<IncidentsTableAlert>> => serverTableNormalize({
  ...data,
  data: data.data.map((a) => ({
    ...a,
    source: normalizeTicketSources([{
      name_source: a.name_sources,
      id_source: 0,
      disabled: false,
    }]),
  })),
});
