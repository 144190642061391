import type { PropsWithChildren } from 'react';
import { Flex, Center, Text } from '@mantine/core';

import UpsellAction from '@/components/content/upsell/UpsellAction';

import { ERROR_CLASSES, type FetchError } from '@/types/api';
import Button from '@/components/content/button/Button';
import Skeleton from '@/components/content/placeholder/Skeleton';

interface WrapperProps {
  isPending: boolean;
  isFetching: boolean;
  isError: boolean;
  error: FetchError | null;
  refetch: VoidFunction;
  count: number;
  noMatchText?: string;
}

const ResultsWrapper = ({
  isPending,
  isFetching,
  isError,
  error,
  refetch,
  count,
  children,
  noMatchText,
}: PropsWithChildren<WrapperProps>) => {
  const noResultText = `Your search didn't match any ${noMatchText}`;

  if (isPending || isFetching) {
    return (
      <Flex direction="column" gap={25} w="100%">
        <Skeleton n={10} height={50} mt={6} radius="4px" id="search-result-placeholoder" />
      </Flex>
    );
  }

  if (isError && error) {
    if (error.code === 402) {
      return (
        <Center h={300}>
          <Flex direction="column" gap={15} maw="50%">
            <Text size="xl">
              {/* eslint-disable-next-line max-len */}
              Thank you for your interest in this Premium content. This content is available exclusively to our <b>Premium Subscription Customers</b>.
            </Text>
            <UpsellAction />
          </Flex>
        </Center>
      );
    }

    if (error.code === 400 && error.details?.class === ERROR_CLASSES.QUERY_ATTRIBUTE_ERROR) {
      return (
        <Center h={300}>
          <Flex direction="column" gap={15}>
            <Text size="xl">{noResultText}</Text>
          </Flex>
        </Center>
      );
    }

    return (
      <Center h={300}>
        <Flex direction="column" gap={15}>
          <Text size="xl">We are having an unexpected issue loading this results for you</Text>
          <Button onClick={refetch}>Retry</Button>
        </Flex>
      </Center>
    );
  }

  if (!count) {
    return (
      <Center h={300}>
        <Text size="xl">{noResultText}</Text>
      </Center>
    );
  }

  return children;
};

export default ResultsWrapper;
