import { Flex } from '@mantine/core';

import Acknowledge from '../../metadata/components/Acknowledge';
import Resolve from '../../metadata/components/Resolve';
import RequestTakedown from './RequestTakedown';
import PrintButton from '@/components/content/button/PrintButton';

import { incidentsKeys } from '../services/keys';
import { dashboardKeys } from '../../dashboard/services/keys';

import { OIncidentsStatus } from '../types';
import { unifiedApiKeys } from '@/services/keys';
import { INCIDENTS_ENDPOINTS } from '../services/issue';
import { notificationsKeys } from '../../notifications/services/keys';

interface AlertActionsProps {
  issueId: number;
  isAcknowledged: boolean;
  isResolved: boolean;
  status: string;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  print?: boolean;
  subject?: string;
  previousStatus?: string;
}

const AlertActions = ({
  asButton,
  issueId,
  isAcknowledged,
  isResolved,
  loading,
  disabled,
  status,
  print,
  subject,
  previousStatus,
}: AlertActionsProps) => (
  <Flex
    align="center"
    justify="center"
    gap={10}
    flex="1"
    direction={{
      base: asButton ? 'column' : 'row',
      s: 'row',
    }}
  >
    {print && (
      <PrintButton documentTitle={['QuoIntelligence', `${issueId}`, subject || '']} asButton />
    )}

    {status.toLowerCase() !== OIncidentsStatus.Pending && status.toLowerCase() !== OIncidentsStatus.Resolved && (
      <Acknowledge
        issueId={issueId}
        isAcknowledged={isAcknowledged}
        resource={INCIDENTS_ENDPOINTS.incidents}
        previousStatus={previousStatus || OIncidentsStatus.New}
        invalidate={[
          notificationsKeys.notifications(),
          unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
          incidentsKeys.summary(),
          incidentsKeys.gfx(incidentsKeys.alerts),
          incidentsKeys.table(incidentsKeys.alerts),
          dashboardKeys.alerts(),
        ]}
        loading={loading}
        disabled={disabled}
        asButton={asButton}
      />
    )}

    <Resolve
      issueId={issueId}
      isResolved={isResolved}
      resource={INCIDENTS_ENDPOINTS.incidents}
      previousStatus={previousStatus || OIncidentsStatus.New}
      invalidate={[
        unifiedApiKeys.ticket(INCIDENTS_ENDPOINTS.incidents, issueId),
        incidentsKeys.table(incidentsKeys.alerts),
        dashboardKeys.alerts(),
      ]}
      loading={loading}
      disabled={disabled}
      asButton={asButton}
    />

    <RequestTakedown
      issueId={issueId}
      disabled={disabled}
      loading={loading}
      asButton={asButton}
    />
  </Flex>
);

export default AlertActions;
