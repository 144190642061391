import { Group, Text, Tooltip } from '@mantine/core';

import BaseResult from './BaseResult';
import CatalogLinkList from '../../cataloglink/CatalogLinkList';
import IconMap from '../../iconmap/IconMap';
import List from '@/components/content/list/List';
import LocationIcon from '../../iconmap/LocationIcon';
import Pill from '@/components/content/pills/Pill';
import IssueWrapperV2 from '../../issue/components/IssueWrapper';

import { toIsoDate } from '@/utils/toDate';
import { getContextData } from '../../catalogs/utils/context';
import { sourcesDrawer } from '../../catalogs/features/sources/pages/sources.drawer';
import { objectHasValues } from '@/utils';
import { CATEGORY_ICONS } from '../../iconmap/icons/intel_category';
import { INDUSTRY_ICON_MAP } from '../../iconmap/icons/industry';
import { signalDrawer } from '../../analyticsdashboard/components/signals.drawer';
import { Signal } from '@/types/mercury-data-types';
import { normalizeSignal } from '@/services/unifiedapi/signals';

const SignalSearchResult = ({ data }: { data: Signal }) => {
  const signal = normalizeSignal(data);

  const {
    id,
    title,
    text,
    timestamp,
    catalog: {
      source,
      source_id: sourceId,
      category,
      threatactor,
      malwarekit,
      vulnerability,
      locations,
      industry_sectors: industries,
    },
  } = signal;

  const context = getContextData(
    { threatactor, malwarekit, vulnerability },
    ['threatactor', 'malwarekit', 'vulnerability'],
  );

  return (
    <BaseResult
      title={{ id, name: title }}
      content={text}
      drawerContent={(
        <IssueWrapperV2
          top={signalDrawer}
          data={signal}
          id={id}
          removePadding
        />
      )}
      footer={[
        timestamp
        && (
          <Text size="sm">
            <Text fw="bold" span>Date:</Text> {toIsoDate(timestamp)}
          </Text>
        ),
        sourceId && sourceId.length > 0
        && (
          <Group gap={5}>
            <Text size="sm" fw="bold" span>Source:</Text>
            <CatalogLinkList
              value={sourceId.map((id: any, index: any) => ({ id, name: source[index] }))}
              catalog="sources"
              content={sourcesDrawer}
            />
          </Group>
        ),
        category
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Category:</Text>
            <IconMap
              id={id}
              icons={CATEGORY_ICONS}
              map={{ [category]: category }}
              layout="small"
              renderLabelWithoutIcon={(label) => (
                <Pill
                  component="span"
                  bg="light-dark(var(--icon-column-stroke), var(--quo-blue)"
                  color="var(--quo-white)"
                  value={label}
                />
              )}
              showTooltip
            />
          </Group>
        ),
        objectHasValues(locations)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Locations:</Text>
            <LocationIcon locations={locations} id={id} max={2} layout="small" />
          </Group>
        ),
        objectHasValues(industries)
        && (
          <Group gap={5}>
            <Text fw="bold" size="sm" span>Industries:</Text>
            <IconMap
              id={id}
              map={industries}
              icons={INDUSTRY_ICON_MAP}
              max={3}
              layout="small"
              renderLabelWithoutIcon={(label) => label}
              showTooltip
            />
          </Group>
        ),
        context.length > 0
        && (
          <Group gap={5} align="center">
            <Text fw="bold" size="sm" span>Related Catalogs:</Text>
            <List
              gap={10}
              direction="row"
              align="center"
              style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
              items={context}
              renderItem={([name, count, icon]) => (
                <Tooltip label={name}>
                  <Group align="center" gap={5}>{icon} <Text size="sm" span>{count}</Text></Group>
                </Tooltip>
              )}
            />
          </Group>
        ),
      ]}
    />
  );
};

export default SignalSearchResult;
