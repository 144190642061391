import { useQuery } from '@tanstack/react-query';

import ResultsWrapper from './ResultsWrapper';
import MercuryPagination from '@/components/content/pagination/Pagination';

import { useMercuryPagination } from '@/hooks/useMercuryPagination';
import { unifiedApiKeys } from '@/services/keys';
import { openapiClient } from '@/lib/openapi-fetch';

import type { ResultProp } from '../types';
import type { UnifiedApiResult } from '@/types/mercury-data-types/unifiedapi';

const Results = ({
  endpoint,
  filterBuilder,
  include,
  template: Template,
  query,
  filters,
  title,
}: ResultProp) => {
  const [page, { next, previous, handlePagination }] = useMercuryPagination();

  const LENGTH = 10;

  const params = {
    start: page * LENGTH,
    filter: [...filterBuilder(filters), query],
    length: LENGTH,
    include,
  };

  const results = useQuery({
    queryKey: unifiedApiKeys.records(endpoint, params),
    queryFn: async () => (
      openapiClient.GET(
        endpoint,
        {
          params: {
            query: params,
          },
        },
      )
    ),
    select: (data) => {
      const unifiedData = data.data as UnifiedApiResult;

      return {
        ...data.data as unknown as UnifiedApiResult,
        pages: Math.ceil((unifiedData.count) / LENGTH),
      };
    },
  });

  return (
    <div>
      <ResultsWrapper
        isPending={results.isPending}
        isFetching={results.isFetching}
        isError={results.isError}
        error={results.error}
        refetch={results.refetch}
        count={results.data?.count || 0}
        noMatchText={title}
      >
        {Template && results.data?.records.map((record) => (
          <Template data={record} key={record.id} />
        ))}

        <MercuryPagination
          value={page + 1}
          total={results.data?.pages as number}
          onChange={(p) => handlePagination(p - 1)}
          onNextPage={next}
          onPreviousPage={previous}
        />
      </ResultsWrapper>
    </div>
  );
};

export default Results;
